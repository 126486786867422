import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import RouterPaths from "../common/constants/RouterPaths";
import { PrepareUrl } from "../common/helpers/PrepareUrl";

const LocationsTableRow = ({ item, history, isAdmin }) => {
  const { t } = useTranslation();
  return (
    <>
      <td>{item.locationName}</td>
      <td>{t(item.locationType)}</td>
      <td>{item.deviceUrl}</td>
      <td className="text-right pr-3">
        <>
          <Button
            className="btn-icon"
            color="success"
            id={`tooltip${item.locationId}`}
            size="sm"
            onClick={() =>
              history.push(
                PrepareUrl(
                  isAdmin
                    ? RouterPaths.ADMIN_LOCATION_USERS
                    : RouterPaths.LOCATION_USERS,
                  {
                    id: item.locationId
                  }
                )
              )
            }
          >
            <i className="fa fa-users" />
          </Button>
          {isAdmin && (
            <Button
              className="btn-icon"
              color="success"
              id={`admin-tooltip${item.locationId}`}
              size="sm"
              onClick={() =>
                history.push(
                  PrepareUrl(RouterPaths.ADMIN_LOCATION_ADMINS, {
                    id: item.locationId
                  })
                )
              }
            >
              <i className="fa fa-users-cog" />
              <UncontrolledTooltip
                delay={0}
                target={`admin-tooltip${item.locationId}`}
              >
                {t("administrators")}
              </UncontrolledTooltip>
            </Button>
          )}
          <UncontrolledTooltip delay={0} target={`tooltip${item.locationId}`}>
            {t("users")}
          </UncontrolledTooltip>
        </>
      </td>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  isAdmin: auth.get("isAdmin")
});

export default connect(mapStateToProps)(withRouter(LocationsTableRow));
