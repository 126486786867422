import React from "react";
import { Button, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import { useTranslation } from "react-i18next";
import { Form } from "formik";
import FormInputField, {
  FormInputFieldType
} from "../common/components/FormInputField";

const ProfileForm = ({ isAdmin }) => {
  const { t } = useTranslation("profile");
  return (
    <>
      <Form autoComplete="off">
        <CardHeader>
          <CardTitle>
            <div className="d-flex">
              <h4 className="flex-grow-1">{t("editForm.title")}</h4>
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="form-horizontal">
            <FormInputField
              name="email"
              label={t("editForm.email")}
              disabled={!isAdmin}
            />
            <FormInputField
              autoComplete="new-password"
              name="password"
              label={t("editForm.password")}
              type={FormInputFieldType.PASSWORD}
            />
            <FormInputField
              autoComplete="new-password"
              name="confirmPassword"
              label={t("editForm.confirmPassword")}
              type={FormInputFieldType.PASSWORD}
            />
          </div>
          <Row>
            <Col md="3" />
            <Col md="9" justify="start" className="text-right">
              <Button className="btn-round" color="info" type="submit">
                {t("editForm.submitButton")}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Form>
    </>
  );
};

export default ProfileForm;
