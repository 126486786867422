import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import dateFormat from "dateformat";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { PrepareUrl } from "../../common/helpers/PrepareUrl";
import RouterPaths from "../../common/constants/RouterPaths";

const LocationUsersTableRow = ({ item, history }) => {
  const { t } = useTranslation();
  return (
    <>
      <td>{item.phoneNumber}</td>
      <td>{item.userName}</td>
      <td>{item.expirationDate || "-"}</td>
      <td>{item.daysPerYear || "-"}</td>
      <td>{dateFormat(item.createdTime, "yyyy-mm-dd")}</td>
      <td className="text-right">
        {item.userLocationId && (
          <>
            <Button
              className="btn-icon"
              color="success"
              id={`tooltip${item.id}`}
              size="sm"
              onClick={() =>
                history.push(
                  PrepareUrl(RouterPaths.LOCATION_USER_EDIT, {
                    id: item.userLocationId
                  })
                )
              }
            >
              <i className="fa fa-edit" />
            </Button>
            <UncontrolledTooltip delay={0} target={`tooltip${item.id}`}>
              {t("edit")}
            </UncontrolledTooltip>
          </>
        )}
      </td>
    </>
  );
};

export default withRouter(LocationUsersTableRow);
