import { apiClient } from "../common/api/ApiClient";
import {
  handleError,
  showAlert
} from "../common/components/alert/AlertActions";
import AlertVariant from "../common/components/alert/AlertVariant";
import { getUserInfo } from "../auth/AuthActions";

export const updateProfile = (email, password, passwordConfirm) => dispatch => {
  return apiClient
    .put(`/authorized/profile`, {
      email,
      password,
      passwordConfirm
    })
    .then(() => {
      dispatch(getUserInfo());
      dispatch(showAlert("profile:updateSuccess", AlertVariant.SUCCESS, 5000));
    })
    .catch(err => {
      dispatch(handleError(err));
      throw err;
    });
};
