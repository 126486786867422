import React from "react";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";

const DashboardStatsCard = ({ title, value, iconClass }) => {
  const { t } = useTranslation();
  return (
    <Card className="card-pricing">
      <CardBody>
        <div className="card-icon icon-primary">
          <i className={iconClass} />
        </div>
        <h3 className="card-title">{value}</h3>
        <ul>
          <li>{t(title)}</li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default DashboardStatsCard;
