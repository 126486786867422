import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchLocations } from "./LocationsActions";
import CommonTable from "../common/components/CommonTable";
import LocationsTableRow from "./LocationsTableRow";
import CommonLoader, { LoaderStatus } from "../common/components/CommonLoader";

const headers = [
  {
    label: "locations:locationName"
  },
  {
    label: "locations:locationType"
  },
  {
    label: "locations:deviceUrlLabel"
  },
  {
    label: "common:actions",
    className: "text-right"
  }
];

const adminHeaders = headers.filter(item => item.label !== "actions");

const LocationsPage = ({ fetchLocations, isAdmin }) => {
  const [loaderStatus, setLoaderStatus] = useState(LoaderStatus.NOT_PENDING);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState({
    items: [],
    total: 0
  });

  useEffect(() => {
    setLoaderStatus(LoaderStatus.IN_PROGRESS);
    fetchLocations(offset).then(res => {
      if (res && res.status === 200 && res.data) {
        const { total, items } = res.data;
        setData({
          total,
          items
        });
      }
      setLoaderStatus(
        res && res.status === 200 ? LoaderStatus.SUCCESS : LoaderStatus.FAILURE
      );
    });
  }, [offset, fetchLocations]);

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <CommonLoader status={loaderStatus}>
            <CommonTable
              rowComponent={LocationsTableRow}
              data={data}
              offset={offset}
              onOffsetChange={setOffset}
              headers={isAdmin ? adminHeaders : headers}
              rowKey="locationId"
              title="locations:title"
            />
          </CommonLoader>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  isAdmin: auth.get("isAdmin")
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchLocations }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LocationsPage);
