import jwt from "jsonwebtoken";
import { apiClient, setAuthorizationHeader } from "../common/api/ApiClient";
import {
  GET_USER_INFO_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT
} from "./AuthReducer";
import { AUTH_TOKEN } from "./AuthConstants";
import { ADMIN_PREFIX } from "../common/constants/RouterPaths";
import history from "../history";
import { handleError } from "../common/components/alert/AlertActions";

export const login = (email, password) => dispatch =>
  apiClient
    .post("/admin/login", { email, password })
    .then(res => {
      const { status, token } = res.data;
      if (res.status === 200 && status === "SUCCESS" && token) {
        localStorage.setItem(AUTH_TOKEN, token);
        setUserInSession(dispatch);
        const decoded = jwt.decode(token);
        history.push(decoded.roles === "SUPER_ADMIN" ? ADMIN_PREFIX : "/");
      } else {
        dispatch({ type: LOGIN_FAILURE });
      }
    })
    .catch(err => dispatch(handleError(err)));

export const setUserInSession = dispatch => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  if (authToken) {
    const decoded = jwt.decode(authToken);
    dispatch({ type: LOGIN_SUCCESS, payload: decoded });
  }
  setAuthorizationHeader(authToken, () => dispatch(logout()));
};

function deleteTokens() {
  localStorage.removeItem(AUTH_TOKEN);
}

export const logout = () => dispatch => {
  deleteTokens();
  dispatch({ type: LOGOUT });
  setAuthorizationHeader(null, () => {});
};

export const getUserInfo = () => (dispatch, getState) => {
  if (getState().auth.get("isAuthenticated")) {
    apiClient
      .get("/authorized/me")
      .then(res => res.data)
      .then(email => dispatch({ type: GET_USER_INFO_SUCCESS, payload: email }))
      .catch(err => dispatch(handleError(err)));
  }
};
