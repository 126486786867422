import { fromJS } from "immutable";

const initialData = fromJS({
  isAuthenticated: false,
  isAdmin: false,
  email: ""
});

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";

export default (state = initialData, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return state.merge({
        isAuthenticated: true,
        isAdmin: action.payload.roles === "SUPER_ADMIN",
        id: action.payload.sub
      });
    case LOGOUT:
    case LOGIN_FAILURE:
      return state.merge(initialData);
    case GET_USER_INFO_SUCCESS:
      return state.merge({
        email: action.payload
      });
    default:
      return state;
  }
};
