import React from "react";
import { Redirect, Switch } from "react-router-dom";

import AdminNavbar from "common/components/Navbars/LoggedNavbar.js";
import Footer from "common/components/Footer/Footer.js";
import Sidebar from "common/components/Sidebar/Sidebar.js";

import routes from "routes.js";
import { connect } from "react-redux";
import { uuid } from "uuidv4";
import RouterPaths, { ADMIN_PREFIX } from "../common/constants/RouterPaths";
import ProtectedRoute from "../common/hoc/ProtectedRoute";

class MainLayout extends React.Component {
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    return routes.map(prop => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      return (
        <ProtectedRoute
          path={prop.path}
          component={prop.component}
          key={uuid()}
        />
      );
    });
  };

  render() {
    return (
      <div>
        <Sidebar {...this.props} />
        <div className="main-panel">
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect
              to={this.props.isAdmin ? ADMIN_PREFIX : RouterPaths.DASHBOARD}
            />
          </Switch>
          {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname.indexOf("full-screen-map") !==
          -1 ? null : (
            <Footer fluid />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAdmin: auth.get("isAdmin")
});

export default connect(mapStateToProps)(MainLayout);
