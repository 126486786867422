import { Redirect, Route } from "react-router-dom";
import React from "react";
import connect from "react-redux/es/connect/connect";
import RouterPaths from "../constants/RouterPaths";
import ProtectedRoute from "./ProtectedRoute";

const ProtectedAdminRoute = ({
  component: Component,
  isAuthenticated,
  isAdmin,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAdmin ? (
        <ProtectedRoute component={Component} {...props} />
      ) : (
        <Redirect to={RouterPaths.LOGIN} />
      )
    }
  />
);

const mapStateToProps = function({ auth }) {
  return {
    isAuthenticated: auth.get("isAuthenticated"),
    isAdmin: auth.get("isAdmin")
  };
};

export default connect(mapStateToProps)(ProtectedAdminRoute);
