import { fromJS } from "immutable";

export const SHOW_ALERT = "SHOW_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

const initialData = fromJS({
  show: false,
  text: "",
  variant: undefined,
  duration: undefined
});

export default (state = initialData, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return state.merge({
        show: true,
        ...action.payload
      });
    case CLOSE_ALERT:
      return state.merge({
        show: false
      });
    case CLEAR_ALERT:
      return state.merge({
        text: "",
        variant: null
      });
    default:
      return state;
  }
};
