import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Container } from "reactstrap";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ValidationSchema from "./CreateAdminUserForm.schema";
import { handleError } from "../../../common/components/alert/AlertActions";
import { createAdminUser } from "../AdminUsersActions";
import AdminUserForm from "../AdminUserForm";
import RouterPaths from "../../../common/constants/RouterPaths";

const initialValues = {
  email: "",
  password: "",
  confirmPassword: ""
};

const CreateAdminUserPage = ({ createAdminUser, history }) => {
  const { t } = useTranslation();
  const handleSubmit = (fields, { setSubmitting }) => {
    createAdminUser(fields).catch(() => setSubmitting(false));
  };

  return (
    <div className="content">
      <Card>
        <CardBody>
          <Button
            onClick={() => history.push(RouterPaths.ADMIN_USERS)}
            color="primary"
          >
            <i className="fa fa-chevron-left" /> {t("common:back")}
          </Button>
        </CardBody>
      </Card>
      <Card>
        <Container>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
            validateOnBlur={true}
          >
            <AdminUserForm title="createForm.title" />
          </Formik>
        </Container>
      </Card>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ handleError, createAdminUser }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(withRouter(CreateAdminUserPage));
