import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { uuid } from "uuidv4";
import TablePagination from "./TablePagination";

const CommonTable = ({
  offset,
  title,
  titleTranslated = false,
  onOffsetChange,
  data,
  headers,
  rowComponent: RowComponent,
  rowKey,
  rowAdditionalProps
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">{titleTranslated ? title : t(title)}</CardTitle>
      </CardHeader>
      <CardBody>
        <Table responsive>
          <thead className="text-primary">
            <tr>
              <th className="text-center">#</th>
              {headers.map(header => (
                <th key={header.label} className={header.className}>
                  {t(header.label)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!isEmpty(data.items) &&
              data.items.map((item, index) => (
                <tr key={item[rowKey] || item.id || uuid()}>
                  <td className="text-center">{offset + index + 1}</td>
                  <RowComponent item={item} {...rowAdditionalProps} />
                </tr>
              ))}
            {isEmpty(data.items) && (
              <tr>
                <td colSpan={headers.length + 1}>
                  <br />
                  <div className="alert alert-info">{t("noData")}</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="mt-2">
          <TablePagination
            total={data.total}
            offset={offset}
            onOffsetChange={offset => onOffsetChange(offset)}
          />
        </div>
      </CardBody>
    </Card>
  );
};

CommonTable.defaultProps = {
  titleTranslated: false,
  offset: 0,
  onOffsetChange: () => {},
  rowKey: undefined
};

CommonTable.propTypes = {
  offset: PropTypes.number,
  title: PropTypes.string.isRequired,
  onOffsetChange: PropTypes.func,
  data: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  rowComponent: PropTypes.any.isRequired,
  titleTranslated: PropTypes.bool,
  rowKey: PropTypes.string
};

export default CommonTable;
