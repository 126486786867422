import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import { useTranslation } from "react-i18next";
import { Form as FormikForm, Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AssignLocationFormSchema from "./AssignLocationForm.schema";
import FormInputField, {
  FormInputFieldType
} from "../../../common/components/FormInputField";
import { assignLocation, fetchLocation } from "../../LocationsActions";
import RouterPaths from "../../../common/constants/RouterPaths";
import { PrepareUrl } from "../../../common/helpers/PrepareUrl";
import {
  handleError,
  showAlert
} from "../../../common/components/alert/AlertActions";
import CommonLoader, {
  LoaderStatus
} from "../../../common/components/CommonLoader";
import AlertVariant from "../../../common/components/alert/AlertVariant";

const AssignLocationPage = ({
  history,
  handleError,
  fetchLocation,
  showAlert
}) => {
  const { id } = useParams();
  const { t } = useTranslation("locations");
  const [loaderStatus, setLoaderStatus] = useState(LoaderStatus.NOT_PENDING);
  const [location, setLocation] = useState("");

  useEffect(() => {
    setLoaderStatus(LoaderStatus.IN_PROGRESS);
    fetchLocation(id)
      .then(res => {
        const { locationId, locationName } = res;
        setLocation({
          locationId,
          locationName
        });
        setLoaderStatus(LoaderStatus.SUCCESS);
      })
      .catch(() => {});
  }, [id, fetchLocation]);

  const handleSubmit = (fields, { setSubmitting }) => {
    assignLocation(id, fields)
      .then(() => {
        showAlert("locations:assignForm.success", AlertVariant.SUCCESS, 5000);
        history.push(PrepareUrl(RouterPaths.LOCATION_USERS, { id }));
      })
      .catch(err => {
        setSubmitting(false);
        handleError(err);
      });
  };
  console.log(location);
  return (
    <div className="content">
      <Card>
        <CardBody>
          <Button
            onClick={() =>
              location
                ? history.push(
                    PrepareUrl(RouterPaths.LOCATION_USERS, {
                      id: location.locationId
                    })
                  )
                : history.goBack()
            }
            color="primary"
          >
            <i className="fa fa-chevron-left" /> {t("common:back")}
          </Button>
        </CardBody>
      </Card>
      <Card>
        <Container>
          <CommonLoader status={loaderStatus}>
            <Formik
              initialValues={{}}
              validationSchema={AssignLocationFormSchema}
              onSubmit={handleSubmit}
              validateOnBlur={true}
            >
              <FormikForm>
                <CardHeader>
                  <CardTitle>
                    <div className="d-flex">
                      <h4 className="flex-grow-1">
                        {t("assignForm.title")}{" "}
                        {location ? location.locationName : ""}
                      </h4>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="form-horizontal">
                    <FormInputField
                      name="phone"
                      label={t("assignForm.phone")}
                      type={FormInputFieldType.NUMBER}
                    />
                    <FormInputField
                      name="username"
                      label={t("assignForm.username")}
                    />

                    <FormInputField
                      name="expirationDate"
                      label={t("assignForm.expirationDate")}
                      type={FormInputFieldType.DATE}
                    />
                    <FormInputField
                      name="daysPerYear"
                      label={t("assignForm.daysPerYear")}
                      type={FormInputFieldType.NUMBER}
                    />
                  </div>
                  <Row>
                    <Col md="3" />
                    <Col md="9" className="text-right">
                      <Button className="btn-round" color="info" type="submit">
                        {t("assignForm.submitButton")}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </FormikForm>
            </Formik>
          </CommonLoader>
        </Container>
      </Card>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ handleError, fetchLocation, showAlert }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(withRouter(AssignLocationPage));
