import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { useParams, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CardBody from "reactstrap/es/CardBody";
import Card from "reactstrap/es/Card";
import CommonTable from "../../common/components/CommonTable";
import CommonLoader, {
  LoaderStatus
} from "../../common/components/CommonLoader";
import LocationUsersTableRow from "./LocationUsersTableRow";
import { fetchLocationUsers } from "../LocationsActions";
import RouterPaths from "../../common/constants/RouterPaths";
import { PrepareUrl } from "../../common/helpers/PrepareUrl";
import LocationUsersSearch from "./LocationUsersSearch";

const headers = [
  {
    label: "locations:users.phone"
  },
  {
    label: "locations:users.username"
  },
  {
    label: "locations:users.expirationDate"
  },
  {
    label: "locations:users.daysPerYear"
  },
  {
    label: "locations:users.createdTime"
  },
  {
    label: "actions",
    className: "text-right"
  }
];

const navigateToAssignment = (history, locationId) =>
  history.push(
    PrepareUrl(RouterPaths.ASSIGN_LOCATION, {
      id: locationId
    })
  );

const LocationUsersPage = ({ history }) => {
  const { id } = useParams();

  const [loaderStatus, setLoaderStatus] = useState(LoaderStatus.NOT_PENDING);
  const [searchParams, setSearchParams] = useState({
    offset: 0,
    phone: "",
    username: ""
  });
  const [data, setData] = useState({
    users: {
      items: [],
      total: 0
    }
  });

  useEffect(() => {
    setLoaderStatus(LoaderStatus.IN_PROGRESS);
    fetchLocationUsers(
      id,
      searchParams.phone,
      searchParams.username,
      searchParams.offset
    )
      .then(res => {
        if (res && res.status === 200 && res.data) {
          const { location, users } = res.data;
          setData({
            location,
            users
          });
        }
        setLoaderStatus(
          res && res.status === 200
            ? LoaderStatus.SUCCESS
            : LoaderStatus.FAILURE
        );
      })
      .catch(() => {});
  }, [id, searchParams]);

  const { t } = useTranslation();

  const handleSearch = (phone, username) => {
    setSearchParams({
      phone,
      username,
      offset: 0
    });
  };

  const handleOffsetChange = offset => {
    setSearchParams({
      ...searchParams,
      offset
    });
  };

  return (
    <div className="content">
      <Col xs="12">
        <Card>
          <CardBody>
            <Button
              onClick={() => history.push(RouterPaths.LOCATIONS)}
              color="primary"
            >
              <i className="fa fa-chevron-left" /> {t("common:back")}
            </Button>
            <Button
              color="info"
              type="button"
              onClick={() => navigateToAssignment(history, id)}
            >
              {t("locations:assignLocationButton")}
            </Button>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <LocationUsersSearch onSearch={handleSearch} />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <CommonLoader status={loaderStatus}>
          <CommonTable
            rowComponent={LocationUsersTableRow}
            data={data.users}
            offset={searchParams.offset}
            onOffsetChange={handleOffsetChange}
            headers={headers}
            titleTranslated
            rowKey="userLocationId"
            title={
              data.location
                ? `${t(data.location.locationType)} : ${
                    data.location.locationName
                  }`
                : `${t("users")}`
            }
          />
        </CommonLoader>
      </Col>
    </div>
  );
};

export default withRouter(LocationUsersPage);
