import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    email: Yup.string()
      .email("validators:invalidEmail")
      .required("validators:required"),
    password: Yup.string().required("validators:required"),
    confirmPassword: Yup.string()
      .required("validators:required")
      .oneOf([Yup.ref("password"), null], "validators:passwordNotMatch")
  });
};
