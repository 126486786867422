import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { login } from "./AuthActions";
import LoginSchema from "./Login.schema";

const Login = ({ login }) => {
  useEffect(() => {
    document.body.classList.toggle("login-page");
    return () => {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState({});

  const authenticate = event => {
    event.preventDefault();
    setErrors({});
    LoginSchema.validate(
      {
        email,
        password
      },
      { abortEarly: false }
    )
      .then(() => {
        login(email, password);
      })
      .catch(validation => {
        const err = {};
        validation.inner.forEach(e => {
          err[e.path] = e.message;
        });

        setErrors({
          ...err
        });
      });
  };
  const { t } = useTranslation();

  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form onSubmit={authenticate} className="form">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">
                      {t("loginPage:title")}
                    </h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t("loginPage:email")}
                      type="text"
                      value={email}
                      invalid={!!errors.email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    {errors && errors.email && (
                      <FormFeedback>{t(errors.email)}</FormFeedback>
                    )}
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t("loginPage:password")}
                      type="password"
                      autoComplete="off"
                      value={password}
                      invalid={!!errors.password}
                      onChange={e => setPassword(e.target.value)}
                    />
                    {errors && errors.password && (
                      <FormFeedback>{t(errors.password)}</FormFeedback>
                    )}
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="btn-round mb-3"
                    color="primary"
                    type="submit"
                  >
                    {t("loginPage:loginButton")}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`
        }}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({ login }, dispatch);

export default connect(null, mapDispatchToProps)(Login);
