import axios from "axios";

let authAuthInterceptor = null;

const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "http://192.168.15.24:9010";

export const apiClient = axios.create({
  baseURL: `${BACKEND_URL}/api/v1`,
  timeout: 5000
});

apiClient.interceptors.response.use(res => {
  if (res && res.data && res.data.errorCode) {
    throw res.data;
  }
  return res;
});

const addAuthInterceptor = logoutAction => {
  authAuthInterceptor = apiClient.interceptors.response.use(
    res => res,
    error => {
      if (error && error.response && error.response.status === 401) {
        logoutAction();
      }
      throw error;
    }
  );
};
const removeAuthInterceptor = () => {
  if (authAuthInterceptor) {
    apiClient.interceptors.response.eject(authAuthInterceptor);
  }
};

export const setAuthorizationHeader = (token, logoutAction) => {
  if (token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    addAuthInterceptor(logoutAction);
  } else {
    removeAuthInterceptor();
    delete apiClient.defaults.headers.common.Authorization;
  }
};
