import React from "react";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const LoaderStatus = {
  NOT_PENDING: "NOT_PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
};

const CommonLoader = ({
  status = LoaderStatus.NOT_PENDING,
  children,
  failureMessage,
  defaultFailureMessage = false
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      {LoaderStatus.FAILURE === status &&
        !failureMessage &&
        !defaultFailureMessage &&
        children}
      {LoaderStatus.FAILURE === status && defaultFailureMessage && (
        <span>{t("fetchFailure")}</span>
      )}
      {LoaderStatus.FAILURE === status && failureMessage}
      {(LoaderStatus.NOT_PENDING === status ||
        LoaderStatus.SUCCESS === status) &&
        children}
      {LoaderStatus.IN_PROGRESS === status && (
        <div className="text-center p-5">
          <Loader height={40} color="#5bc1ff" type="Bars" />
        </div>
      )}
    </>
  );
};

CommonLoader.defaultProps = {
  defaultFailureMessage: false,
  failureMessage: undefined
};

CommonLoader.propTypes = {
  status: PropTypes.oneOf(Object.keys(LoaderStatus)).isRequired,
  defaultFailureMessage: PropTypes.bool,
  failureMessage: PropTypes.string
};

export default CommonLoader;
