import { apiClient } from "../common/api/ApiClient";
import { handleError } from "../common/components/alert/AlertActions";

export const fetchDashboardStats = () => dispatch =>
  apiClient
    .get("/authorized/admin/statistics")
    .then(res => res.data)
    .catch(err => {
      dispatch(handleError(err));
      throw err;
    });

export const fetchUsageStats = () => dispatch =>
  apiClient
    .get("/authorized/stats/opens")
    .then(res => res.data)
    .catch(err => {
      dispatch(handleError(err));
      throw err;
    });
