import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    email: Yup.string()
      .email("validators:invalidEmail")
      .required("validators:required"),
    password: Yup.string(),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "validators:passwordNotMatch"
    )
  });
};
