import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import { useTranslation } from "react-i18next";
import { Form as FormikForm, Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import ValidationSchema from "./LocationUserEditForm.schema";
import FormInputField, {
  FormInputFieldType
} from "../../../common/components/FormInputField";
import {
  deleteUserLocation,
  fetchLocation,
  fetchUserLocation,
  updateUserLocation
} from "../../LocationsActions";
import RouterPaths from "../../../common/constants/RouterPaths";
import { PrepareUrl } from "../../../common/helpers/PrepareUrl";
import {
  handleError,
  showAlert
} from "../../../common/components/alert/AlertActions";
import CommonConfirmDialog from "../../../common/components/CommonConfirmDialog";
import CommonLoader, {
  LoaderStatus
} from "../../../common/components/CommonLoader";
import AlertVariant from "../../../common/components/alert/AlertVariant";

const LocationUserEditPage = ({
  history,
  handleError,
  deleteUserLocation,
  fetchUserLocation,
  updateUserLocation,
  showAlert,
  fetchLocation,
  isAdmin
}) => {
  const { id } = useParams();
  const { t } = useTranslation("locations");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(LoaderStatus.IN_PROGRESS);
  const [locationName, setLocationName] = useState();

  const handleSubmit = (fields, { setSubmitting }) => {
    updateUserLocation(id, fields)
      .then(() => {
        showAlert("locations:editForm.success", AlertVariant.SUCCESS, 5000);
        history.push(
          PrepareUrl(RouterPaths.LOCATION_USERS, {
            id: userLocation.locationId
          })
        );
      })
      .catch(err => {
        setSubmitting(false);
        handleError(err);
      });
  };

  useEffect(() => {
    fetchUserLocation(id).then(response => {
      if (response) {
        const {
          locationId,
          phoneNumber,
          userName,
          expirationDate,
          daysPerYear
        } = response;
        setUserLocation({
          locationId,
          phone: phoneNumber,
          username: userName,
          expirationDate,
          daysPerYear
        });
        fetchLocation(response.locationId).then(res =>
          setLocationName(get(res, "locationName", ""))
        );
        setLoaderStatus(LoaderStatus.SUCCESS);
      } else {
        setLoaderStatus(LoaderStatus.FAILURE);
      }
    });
  }, [id, fetchLocation, fetchUserLocation, history]);

  return (
    <div className="content">
      <Card>
        <CardBody>
          <Button
            onClick={() =>
              userLocation
                ? history.push(
                    PrepareUrl(
                      isAdmin
                        ? RouterPaths.ADMIN_LOCATION_USERS
                        : RouterPaths.LOCATION_USERS,
                      { id: userLocation.locationId }
                    )
                  )
                : history.goBack()
            }
            color="primary"
          >
            <i className="fa fa-chevron-left" /> {t("common:back")}
          </Button>
        </CardBody>
      </Card>
      <CommonConfirmDialog
        title="locations:editForm.deleteDialog.title"
        content="locations:editForm.deleteDialog.content"
        positiveButtonText="locations:editForm.deleteDialog.deleteButton"
        open={showDeleteDialog}
        handleClose={() => setShowDeleteDialog(false)}
        onConfirmed={() => {
          setShowDeleteDialog(false);
          setTimeout(
            () => deleteUserLocation(id, userLocation.locationId),
            500
          );
        }}
      />
      <Card className="pb-5">
        <Container>
          <Formik
            initialValues={userLocation}
            validationSchema={ValidationSchema()}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validateOnBlur={true}
          >
            <FormikForm>
              <CardHeader>
                <CardTitle>
                  <div className="d-flex">
                    <h4 className="flex-grow-1">
                      {locationName
                        ? `${t("locations:editForm.titleTo")} ${locationName}`
                        : t("locations:editForm.title")}
                    </h4>
                    {userLocation && (
                      <Button
                        close
                        className="location-user-edit-delete-btn"
                        onClick={() => setShowDeleteDialog(true)}
                      >
                        <i className="fa fa-trash" />
                      </Button>
                    )}
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <CommonLoader status={loaderStatus} defaultFailureMessage>
                  <div className="form-horizontal">
                    <FormInputField
                      name="phone"
                      label={t("editForm.phone")}
                      type={FormInputFieldType.NUMBER}
                      disabled
                    />
                    <FormInputField
                      name="username"
                      label={t("editForm.username")}
                    />

                    <FormInputField
                      name="expirationDate"
                      label={t("editForm.expirationDate")}
                      type={FormInputFieldType.DATE}
                    />
                    <FormInputField
                      name="daysPerYear"
                      label={t("editForm.daysPerYear")}
                      type={FormInputFieldType.NUMBER}
                    />
                  </div>
                  <Row>
                    <Col md="3" />
                    <Col md="9" justify="start" className="text-right">
                      <Button className="btn-round" color="info" type="submit">
                        {t("editForm.submitButton")}
                      </Button>
                    </Col>
                  </Row>
                </CommonLoader>
              </CardBody>
            </FormikForm>
          </Formik>
        </Container>
      </Card>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  isAdmin: auth.get("isAdmin")
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleError,
      deleteUserLocation,
      fetchUserLocation,
      updateUserLocation,
      showAlert,
      fetchLocation
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LocationUserEditPage));
