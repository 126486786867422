import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import FormInputField from "../../common/components/FormInputField";
import FormSelectField from "../../common/components/FormSelectField";
import UserStatusOptions from "../../common/constants/UserStatusOptions";

const initialValues = {
  email: ""
};

const AdminUsersSearch = ({ onSearch }) => {
  const { t } = useTranslation("adminUsers");

  const handleSearch = (values, { setSubmitting }) => {
    onSearch(values.email, values.status.value);
    setSubmitting(false);
  };

  const statusOptions = [
    {
      label: t("common:all"),
      value: UserStatusOptions.ALL
    },
    {
      label: t("common:isActive"),
      value: UserStatusOptions.ACTIVE
    },
    {
      label: t("common:notActive"),
      value: UserStatusOptions.NOT_ACTIVE
    }
  ];

  initialValues.status = statusOptions[0];

  return (
    <Formik initialValues={initialValues} onSubmit={handleSearch}>
      {form => (
        <Form>
          <Row>
            <Col xl="6">
              <FormInputField
                name="email"
                label={t("common:email")}
                fullWidth
              />
            </Col>
            <Col xl="3">
              <FormSelectField
                name="status"
                label={t("common:status")}
                options={statusOptions}
              />
            </Col>
            <Col md="auto">
              <Button
                className="search-icon-button nc-icon nc-zoom-split"
                color="primary"
                type="submit"
              />
              <Button
                color="danger"
                type="submit"
                className="search-icon-button nc-icon nc-simple-remove"
                onClick={() => {
                  form.resetForm();
                }}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AdminUsersSearch;
