import React from "react";
import { Button, Modal, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const CommonConfirmDialog = ({
  open,
  title,
  content,
  positiveButtonText,
  negativeButtonText = "cancel",
  onConfirmed,
  handleClose
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal isOpen={open} onClosed={handleClose}>
        <div className="p-3">
          <h5>{t(title)}</h5>
          <div className="text-left py-3">{t(content)}</div>
          <ModalFooter>
            <Button color="secondary" onClick={handleClose}>
              {t(negativeButtonText)}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleClose();
                onConfirmed();
              }}
            >
              {t(positiveButtonText)}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default CommonConfirmDialog;
