import { apiClient } from "../../common/api/ApiClient";
import { handleError } from "../../common/components/alert/AlertActions";

export const fetchLocationAdminUsers = (
  locationId,
  email,
  active,
  type,
  offset,
  pageSize = 10
) => dispatch => {
  let queryString = `/authorized/super_admin/location/admins/${locationId}?email=${email}&offset=${offset}&pageSize=${pageSize}`;
  if (type !== null) {
    queryString += `&type=${type}`;
  }
  if (active !== null) {
    queryString += `&active=${active}`;
  }

  return apiClient
    .get(queryString)
    .then(res => res.data)
    .catch(err => dispatch(handleError(err)));
};
