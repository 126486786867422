import AdminTypeOptions from "common/constants/AdminTypeOptions";
import UserStatusOptions from "common/constants/UserStatusOptions";

export const UserStatusOptionToQueryParam = option => {
  switch (option) {
    case UserStatusOptions.ACTIVE:
      return "true";
    case UserStatusOptions.NOT_ACTIVE:
      return "false";
    case UserStatusOptions.ALL:
      return null;
    default:
      return null;
  }
};

export const AdminTypeOptionToQueryParam = option => {
  switch (option) {
    case AdminTypeOptions.ADMIN:
      return "ADMIN";
    case AdminTypeOptions.SUPER_ADMIN:
      return "SUPER_ADMIN";
    case AdminTypeOptions.ALL:
      return null;
    default:
      return null;
  }
};
