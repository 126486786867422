/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink
} from "reactstrap";
import i18next from "i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RouterPaths from "common/constants/RouterPaths";
import { withRouter } from "react-router-dom";
import { getUserInfo, logout } from "../../../auth/AuthActions";

class LoggedNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent"
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    this.props.getUserInfo();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    const newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState.color = "bg-white";
    } else {
      newState.color = "navbar-transparent";
    }
    this.setState(newState);
  };

  render() {
    return (
      <>
        <Navbar
          className={classnames(
            "navbar-absolute fixed-top navbar-blue",
            this.state.color
          )}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="/">
                <span className="d-none d-md-block">
                  <img src="/logo.png" alt="OpenApp" className="logo-img" />
                </span>
                <span className="d-block d-md-none">
                  <img src="/logo.png" alt="OpenApp" className="logo-img" />
                </span>
              </NavbarBrand>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    className="btn-magnify"
                    onClick={() => this.props.history.push(RouterPaths.PROFILE)}
                  >
                    {i18next.t("common:profile")}
                    &nbsp;
                    <i className="fas fa-user-alt" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="btn-magnify"
                    onClick={() => this.props.logout()}
                  >
                    {i18next.t("common:logout")}
                    &nbsp;
                    <i className="fas fa-sign-out-alt" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ logout, getUserInfo }, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(LoggedNavbar));
