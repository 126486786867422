import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Label,
  Row
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import CommonLoader, {
  LoaderStatus
} from "../../common/components/CommonLoader";
import { fetchLocation } from "../../locations/LocationsActions";
import {
  assignAdminLocation,
  fetchAdminUsers
} from "../users/AdminUsersActions";
import RouterPaths from "../../common/constants/RouterPaths";
import { PrepareUrl } from "../../common/helpers/PrepareUrl";

const AssignAdminLocationPage = ({
  fetchLocation,
  assignAdminLocation,
  history
}) => {
  const { id } = useParams();
  const [location, setLocation] = useState({});
  const [users, setUsers] = useState([]);
  const [pickedAdmin, setPickedAdmin] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(LoaderStatus.IN_PROGRESS);

  const setLoaderSuccessConditionally = useCallback(() => {
    if (loaderStatus !== LoaderStatus.FAILURE) {
      setLoaderStatus(LoaderStatus.SUCCESS);
    }
  }, [loaderStatus]);

  useEffect(() => {
    setLoaderStatus(LoaderStatus.IN_PROGRESS);
    fetchAdminUsers(0, 1000)
      .then(res => {
        setUsers(get(res, "data.items", []));
        setLoaderSuccessConditionally();
      })
      .catch(() => setLoaderStatus(LoaderStatus.FAILURE));
    fetchLocation(id)
      .then(data => {
        setLocation(data);
        setLoaderSuccessConditionally();
      })
      .catch(() => setLoaderStatus(LoaderStatus.FAILURE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetchLocation]);

  const { t } = useTranslation("assignAdminLocation");
  return (
    <div className="content">
      <Card>
        <CardBody>
          <Button
            onClick={() =>
              location
                ? history.push(
                    PrepareUrl(RouterPaths.ADMIN_LOCATION_ADMINS, {
                      id: location.locationId
                    })
                  )
                : history.goBack()
            }
            color="primary"
          >
            <i className="fa fa-chevron-left" /> {t("common:back")}
          </Button>
        </CardBody>
      </Card>
      <Card>
        <Container>
          <CardHeader>
            <CardTitle>
              <div className="d-flex">
                <h4 className="flex-grow-1">{t("title")}</h4>
              </div>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div className="form-horizontal">
              <CommonLoader status={loaderStatus} defaultFailureMessage>
                <>
                  <Row>
                    <Col xs={12} sm={3} className="col-form-label">
                      <Label>{t("location")}</Label>
                    </Col>
                    <Col xs={12} sm={9}>
                      <div className="form-group">
                        <input
                          className="form-control"
                          disabled
                          value={get(location, "locationName")}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} sm={3} className="col-form-label">
                      <Label>{t("adminUser")}</Label>
                    </Col>
                    <Col xs={12} sm={9}>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        onChange={value => setPickedAdmin(value)}
                        value={pickedAdmin}
                        options={users.map(user => {
                          return {
                            value: user.adminId,
                            label: user.email
                          };
                        })}
                        placeholder={t("selectAdmin")}
                      />
                    </Col>
                  </Row>
                  <div className="w-100 text-right">
                    <Button
                      color="info"
                      className="btn-round"
                      disabled={!id || !pickedAdmin}
                      onClick={() => assignAdminLocation(id, pickedAdmin.value)}
                    >
                      {t("submitButton")}
                    </Button>
                  </div>
                </>
              </CommonLoader>
            </div>
          </CardBody>
        </Container>
      </Card>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchLocation, assignAdminLocation, fetchAdminUsers },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AssignAdminLocationPage);
