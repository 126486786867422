import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PrepareUrl } from "../../common/helpers/PrepareUrl";
import RouterPaths from "../../common/constants/RouterPaths";

const AdminUsersTableRow = ({ item, loggedUserId, history }) => {
  const { t } = useTranslation();
  return (
    <>
      <td>{item.email}</td>
      <td>{item.activeStatus ? "Active" : "Not Active"}</td>
      <td className="text-right">
        {item.adminId && (
          <>
            <Button
              className="btn-icon"
              color="success"
              id={`tooltip${item.adminId}`}
              size="sm"
              onClick={() =>
                item.adminId === loggedUserId
                  ? history.push(RouterPaths.PROFILE)
                  : history.push(
                      PrepareUrl(RouterPaths.EDIT_ADMIN_USER, {
                        id: item.adminId
                      })
                    )
              }
            >
              <i className="fa fa-edit" />
            </Button>
            <UncontrolledTooltip delay={0} target={`tooltip${item.adminId}`}>
              {t("edit")}
            </UncontrolledTooltip>
          </>
        )}
      </td>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  loggedUserId: auth.get("id")
});

export default connect(mapStateToProps)(withRouter(AdminUsersTableRow));
