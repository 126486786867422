import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Field } from "formik";
import { useTranslation } from "react-i18next";

export const FormInputFieldType = {
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  TIME: "time",
  SELECT: "select",
  PASSWORD: "password"
};

const FormInputFieldInner = props => {
  const {
    label,
    placeholder,
    type,
    form,
    field,
    autoComplete,
    disabled,
    fullWidth
  } = props;
  const { t } = useTranslation();

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || "";

  const inputElement = (
    <>
      <Input
        placeholder={placeholder || label}
        type={type}
        value={value}
        disabled={disabled || form.isSubmitting}
        autoComplete={autoComplete}
        onBlur={() => form.setFieldTouched(field.name, true)}
        onChange={({ target }) => form.setFieldValue(field.name, target.value)}
      />
      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: "red " }}>{t(error)}</label>
      )}
    </>
  );
  return fullWidth ? (
    <>
      <Row>
        <Col>
          <Label>{label}</Label>
          <FormGroup>{inputElement}</FormGroup>
        </Col>
      </Row>
    </>
  ) : (
    <>
      <Row>
        <Label md="3">{label}</Label>
        <Col md="9">
          <FormGroup>{inputElement}</FormGroup>
        </Col>
      </Row>
    </>
  );
};

const FormInputField = props => {
  return <Field {...props} component={FormInputFieldInner} />;
};

FormInputField.defaultProps = {
  type: FormInputFieldType.TEXT,
  placeholder: ""
};

FormInputField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string
};

export default FormInputField;
