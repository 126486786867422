import React, { useCallback, useEffect, useState } from "react";
import { Button, Col } from "reactstrap";
import { useParams, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CardBody from "reactstrap/es/CardBody";
import Card from "reactstrap/es/Card";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CommonTable from "../../common/components/CommonTable";
import CommonLoader, {
  LoaderStatus
} from "../../common/components/CommonLoader";
import LocationAdminUsersTableRow from "./LocationAdminUsersTableRow";
import { fetchLocationAdminUsers } from "./LocationAdminUsersActions";
import RouterPaths from "../../common/constants/RouterPaths";
import { PrepareUrl } from "../../common/helpers/PrepareUrl";
import { fetchLocation } from "../../locations/LocationsActions";
import LocationAdminUsersSearch from "./LocationAdminUsersSearch";
import {
  UserStatusOptionToQueryParam,
  AdminTypeOptionToQueryParam
} from "../../common/helpers/UserHelpers";

const headers = [
  {
    label: "email"
  },
  {
    label: "type"
  },
  {
    label: "isActive"
  },
  {
    label: "locations:users.createdTime"
  },
  {
    label: "actions",
    className: "text-right"
  }
];

const navigateToAssignment = (history, locationId) =>
  history.push(
    PrepareUrl(RouterPaths.ADMIN_ASSIGN_LOCATION, {
      id: locationId
    })
  );

const LocationAdminUsersPage = ({
  history,
  fetchLocationAdminUsers,
  fetchLocation
}) => {
  const { id } = useParams();

  const [loaderStatus, setLoaderStatus] = useState(LoaderStatus.NOT_PENDING);
  const [searchParams, setSearchParams] = useState({
    offset: 0,
    email: "",
    status: null,
    type: null
  });
  const [users, setUSers] = useState({
    items: [],
    total: 0
  });
  const [location, setLocation] = useState();

  const setLoaderSuccessConditionally = useCallback(() => {
    if (loaderStatus !== LoaderStatus.FAILURE) {
      setLoaderStatus(LoaderStatus.SUCCESS);
    }
  }, [loaderStatus]);

  const handleOffsetChange = offset => {
    setSearchParams({
      ...searchParams,
      offset
    });
  };
  const handleSearch = (email, status, type) => {
    setSearchParams({
      email,
      status: UserStatusOptionToQueryParam(status),
      type: AdminTypeOptionToQueryParam(type),
      offset: 0
    });
  };

  useEffect(() => {
    setLoaderStatus(LoaderStatus.IN_PROGRESS);

    Promise.all([
      fetchLocationAdminUsers(
        id,
        searchParams.email,
        searchParams.status,
        searchParams.type,
        searchParams.offset
      ),
      fetchLocation(id)
    ])
      .then(dataArray => {
        const { items, total } = dataArray[0];
        setUSers({
          items,
          total
        });
        setLocation(dataArray[1]);
        setLoaderSuccessConditionally();
      })
      .catch(() => setLoaderStatus(LoaderStatus.FAILURE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, searchParams, fetchLocationAdminUsers, fetchLocation]);

  const { t } = useTranslation();

  return (
    <div className="content">
      <Col md="12">
        <Card>
          <CardBody>
            <Button
              onClick={() => history.push(RouterPaths.LOCATIONS)}
              color="primary"
            >
              <i className="fa fa-chevron-left" /> {t("common:back")}
            </Button>
            <Button
              color="info"
              type="button"
              onClick={() => navigateToAssignment(history, id)}
            >
              {t("locations:assignLocationButton")}
            </Button>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <LocationAdminUsersSearch onSearch={handleSearch} />
          </CardBody>
        </Card>
        <CommonLoader status={loaderStatus}>
          <CommonTable
            rowComponent={LocationAdminUsersTableRow}
            rowAdditionalProps={{ location }}
            data={users}
            offset={searchParams.offset}
            onOffsetChange={handleOffsetChange}
            headers={headers}
            titleTranslated
            rowKey="adminId"
            title={
              location
                ? `${t(location.locationType)} : ${location.locationName}`
                : `${t("users")}`
            }
          />
        </CommonLoader>
      </Col>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchLocationAdminUsers, fetchLocation }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(withRouter(LocationAdminUsersPage));
