import Profile from "profile/Profile";
import RouterPaths from "./common/constants/RouterPaths";
import UserDashboard from "./dashboard/UserDashboard";
import LocationsPage from "./locations/LocationsPage";
import LocationUsersPage from "./locations/users/LocationUsersPage";
import AssignLocationPage from "./locations/users/assign/AssignLocaitonPage";
import LocationUserEditPage from "./locations/users/edit/LocationUserEditPage";

const routes = [
  {
    path: RouterPaths.DASHBOARD,
    name: "Dashboard",
    icon: "fa fa-th-large",
    component: UserDashboard,
    layout: "/user"
  },
  {
    path: RouterPaths.LOCATIONS,
    name: "Lokalizacje",
    icon: "fa fa-map-marker-alt",
    state: "pagesCollapse",
    component: LocationsPage
  },
  {
    path: RouterPaths.LOCATION_USERS,
    component: LocationUsersPage
  },
  {
    path: RouterPaths.ASSIGN_LOCATION,
    component: AssignLocationPage
  },
  {
    path: RouterPaths.LOCATION_USER_EDIT,
    component: LocationUserEditPage
  },
  {
    path: RouterPaths.PROFILE,
    component: Profile
  }
];

export default routes;
