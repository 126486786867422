import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/AdminLayout.js";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Provider } from "react-redux";
import RouterPaths, { ADMIN_PREFIX } from "./common/constants/RouterPaths";
import { setUserInSession } from "./auth/AuthActions";
import { prepareStore } from "./redux/Store";
import Layout from "./layouts/MainLayout";
import history from "./history";
import "./i18n/i18n";
import AlertContainer from "./common/components/alert/AlertContainer";
import "./App.css";

const store = prepareStore();
setUserInSession(store.dispatch);

ReactDOM.render(
  <Provider store={store}>
    <div className="wrapper wrapper-full-page">
      <Router history={history}>
        <Switch>
          <Route
            path={RouterPaths.LOGIN}
            render={props => <AuthLayout {...props} />}
          />
          <Route
            path={ADMIN_PREFIX}
            render={props => <AdminLayout {...props} />}
          />
          <Route path="/" render={props => <Layout {...props} />} />
        </Switch>
        <AlertContainer />
      </Router>
    </div>
  </Provider>,
  document.getElementById("root")
);
