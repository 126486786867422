import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { fetchAdminUsers } from "./AdminUsersActions";
import CommonTable from "../../common/components/CommonTable";
import AdminUsersTableRow from "./AdminUsersTableRow";
import CommonLoader, {
  LoaderStatus
} from "../../common/components/CommonLoader";
import RouterPaths from "../../common/constants/RouterPaths";
import AdminUsersSearch from "./AdminUsersSearch";
import UserStatusOptions from "../../common/constants/UserStatusOptions";
import { UserStatusOptionToQueryParam } from "../../common/helpers/UserHelpers";

const PAGE_SIZE = 10;
const headers = [
  {
    label: "common:email"
  },
  {
    label: "common:status"
  },
  {
    label: "common:actions",
    className: "text-right"
  }
];

const AdminUsersPage = ({ history }) => {
  const [loaderStatus, setLoaderStatus] = useState(LoaderStatus.NOT_PENDING);
  const [searchParams, setSearchParams] = useState({
    email: "",
    status: UserStatusOptionToQueryParam(UserStatusOptions.ALL),
    offset: 0
  });
  const [data, setData] = useState({
    items: [],
    total: 0
  });

  useEffect(() => {
    setLoaderStatus(LoaderStatus.IN_PROGRESS);
    fetchAdminUsers(
      searchParams.offset,
      PAGE_SIZE,
      searchParams.email,
      searchParams.status
    )
      .then(res => {
        if (res && res.status === 200 && res.data) {
          const { total, items } = res.data;
          setData({
            total,
            items
          });
        }
        setLoaderStatus(
          res && res.status === 200
            ? LoaderStatus.SUCCESS
            : LoaderStatus.FAILURE
        );
      })
      .catch(() => setLoaderStatus(LoaderStatus.FAILURE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onSearchParamsChanged = (email, status) => {
    setSearchParams({
      email,
      status: UserStatusOptionToQueryParam(status),
      offset: 0
    });
  };

  const onOffsetChanged = offset => {
    setSearchParams({
      ...searchParams,
      offset
    });
  };

  const { t } = useTranslation("adminUsers");

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <AdminUsersSearch onSearch={onSearchParamsChanged} />
                </Col>
                <Col xl="auto">
                  <Button
                    onClick={() => history.push(RouterPaths.CREATE_ADMIN_USER)}
                    color="primary"
                    className="add-user-button"
                  >
                    {t("createAdminUser")}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <CommonLoader status={loaderStatus}>
            <CommonTable
              rowComponent={AdminUsersTableRow}
              data={data}
              offset={searchParams.offset}
              onOffsetChange={onOffsetChanged}
              headers={headers}
              title="adminUsers:administrators"
            />
          </CommonLoader>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(AdminUsersPage);
