import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { Button, Card, CardBody, Container } from "reactstrap";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ValidationSchema from "./EditAdminUserForm.schema";
import { handleError } from "../../../common/components/alert/AlertActions";
import {
  fetchAdminUserById,
  updateAdminUser,
  deleteAdminUser
} from "../AdminUsersActions";
import AdminUserForm from "../AdminUserForm";
import { LoaderStatus } from "../../../common/components/CommonLoader";
import RouterPaths from "../../../common/constants/RouterPaths";

const initialValues = {};

const EditAdminUserPage = ({
  loggedUserId,
  updateAdminUser,
  fetchAdminUserById,
  deleteAdminUser,
  history
}) => {
  const { id } = useParams();
  const [adminUser, setAdminUser] = useState();
  const [loaderStatus, setLoaderStatus] = useState(LoaderStatus.NOT_PENDING);
  const [allowDelete, setAllowDelete] = useState(false);

  const handleDelete = () => {
    deleteAdminUser(id);
  };

  const handleSubmit = (fields, { setSubmitting }) => {
    updateAdminUser(id, fields).catch(() => setSubmitting(false));
  };

  useEffect(() => {
    setLoaderStatus(LoaderStatus.IN_PROGRESS);
    fetchAdminUserById(id).then(adminUser => {
      if (adminUser) {
        setLoaderStatus(LoaderStatus.SUCCESS);
        setAdminUser({
          email: adminUser.email,
          password: "",
          confirmPassword: ""
        });
      } else {
        setLoaderStatus(LoaderStatus.FAILURE);
      }
    });
  }, [id, fetchAdminUserById]);

  useEffect(() => {
    setAllowDelete(id !== loggedUserId);
  }, [loggedUserId, id]);

  const { t } = useTranslation();

  return (
    <div className="content">
      <Card>
        <CardBody>
          <Button
            onClick={() => history.push(RouterPaths.ADMIN_USERS)}
            color="primary"
          >
            <i className="fa fa-chevron-left" /> {t("common:back")}
          </Button>
        </CardBody>
      </Card>
      <Card className="pb-5">
        <Container>
          <Formik
            initialValues={adminUser || initialValues}
            validationSchema={ValidationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validateOnBlur={true}
          >
            <AdminUserForm
              title="editForm.title"
              loaderStatus={loaderStatus}
              onDelete={handleDelete}
              allowDelete={allowDelete}
            />
          </Formik>
        </Container>
      </Card>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    loggedUserId: auth.get("id")
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { handleError, updateAdminUser, fetchAdminUserById, deleteAdminUser },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditAdminUserPage));
