import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import FormInputField from "../../common/components/FormInputField";

const initialValues = {
  phone: "",
  username: ""
};

const AdminUsersSearch = ({ onSearch }) => {
  const { t } = useTranslation("adminUsers");

  const handleSearch = (values, { setSubmitting }) => {
    onSearch(values.phone, values.username);
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSearch}>
      {form => (
        <Form>
          <Row>
            <Col xl="5">
              <FormInputField
                name="phone"
                label={t("locations:users.phone")}
                type="number"
                fullWidth
              />
            </Col>
            <Col>
              <FormInputField
                name="username"
                label={t("locations:users.username")}
                fullWidth
              />
            </Col>
            <Col xl="auto">
              <Button
                className="search-icon-button nc-icon nc-zoom-split"
                color="primary"
                type="submit"
              />
              <Button
                color="danger"
                type="submit"
                className="search-icon-button nc-icon nc-simple-remove"
                onClick={() => {
                  form.resetForm();
                }}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AdminUsersSearch;
