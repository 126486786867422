import React from "react";
import { Card, Container, CardBody, Button } from "reactstrap";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { updateProfile } from "./ProfileActions";
import ValidationSchema from "./ProfileForm.schema.js";
import ProfileForm from "./ProfileForm";

const Profile = ({ userEmail, isAdmin, updateProfile, history }) => {
  const { t } = useTranslation("profile");

  const initialValues = {
    email: userEmail,
    password: "",
    confirmPassword: ""
  };

  const onSubmit = (values, { setSubmitting }) => {
    const password = !_.isEmpty(values.password) ? values.password : null;
    const passwordConfirm = !_.isEmpty(values.confirmPassword)
      ? values.confirmPassword
      : null;

    updateProfile(values.email, password, passwordConfirm).then(() => {
      setSubmitting(false);
    });
  };

  return (
    <div className="content">
      <Card>
        <CardBody>
          <Button onClick={() => history.goBack()} color="primary">
            <i className="fa fa-chevron-left" /> {t("common:back")}
          </Button>
        </CardBody>
      </Card>
      <Card className="pb-5">
        <Container>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
            enableReinitialize
          >
            {() => <ProfileForm isAdmin={isAdmin} />}
          </Formik>
        </Container>
      </Card>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  userEmail: auth.get("email"),
  isAdmin: auth.get("isAdmin")
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateProfile }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));
