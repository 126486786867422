import * as Yup from "yup";

const maxDate = new Date("9999-12-31");
export default () => {
  return Yup.object().shape({
    phone: Yup.string()
      .min(9, "validators:phone")
      .max(10, "validators:phone")
      .test("digits test", "validators:phone", value =>
        Number.isInteger(Number(value))
      )
      .required("validators:required"),
    username: Yup.string().required("validators:required"),
    expirationDate: Yup.date().max(maxDate, "validators:incorrectDate")
  });
};
