import RouterPaths from "./common/constants/RouterPaths";
import AdminUsersPage from "./admin/users/AdminUsersPage";
import LocationsPage from "./locations/LocationsPage";
import CreateAdminUserPage from "./admin/users/create/CreateAdminUserPage";
import AssignAdminLocationPage from "./admin/location/AssignAdminLocationPage";
import LocationAdminUsersPage from "./admin/location/LocationAdminUsersPage";
import EditAdminUserPage from "./admin/users/edit/EditAdminUserPage";
import LocationUsersPage from "./locations/users/LocationUsersPage";
import EmptyPage from "./common/EmptyPage";

const routes = [
  {
    name: "Administratorzy",
    path: RouterPaths.ADMIN_USERS,
    icon: "fa fa-users-cog",
    component: AdminUsersPage
  },
  {
    name: "Lokalizacje",
    path: RouterPaths.ADMIN_LOCATIONS,
    icon: "fa fa-map-marker-alt",
    component: LocationsPage
  },
  {
    path: RouterPaths.CREATE_ADMIN_USER,
    component: CreateAdminUserPage
  },
  {
    path: RouterPaths.ADMIN_ASSIGN_LOCATION,
    component: AssignAdminLocationPage
  },
  {
    path: RouterPaths.ADMIN_LOCATION_ADMINS,
    component: LocationAdminUsersPage
  },
  {
    path: RouterPaths.EDIT_ADMIN_USER,
    component: EditAdminUserPage
  },
  {
    path: RouterPaths.ADMIN_LOCATION_USERS,
    component: LocationUsersPage
  },
  {
    path: RouterPaths.ADMIN_EMPTY_PAGE,
    component: EmptyPage
  }
];

export default routes;
