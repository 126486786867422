import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { closeAlert } from "./AlertActions";
import AlertVariant from "./AlertVariant";

const AlertContainer = ({
  open,
  text,
  variant = AlertVariant.PRIMARY,
  closeAlert
}) => {
  const { t } = useTranslation();
  return (
    <>
      {text && (
        <Alert
          isOpen={open}
          color={variant}
          className="alert-container"
          toggle={closeAlert}
        >
          {t(text)}
        </Alert>
      )}
    </>
  );
};

const mapStateToProps = function({ alert }) {
  return {
    text: alert.get("text"),
    variant: alert.get("variant"),
    open: alert.get("show")
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      closeAlert
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
