import React, { useState } from "react";

import { Button, CardHeader, CardTitle, Col, Row } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import { useTranslation } from "react-i18next";
import { Form } from "formik";
import PropTypes from "prop-types";
import ReactBSAlert from "react-bootstrap-sweetalert";
import FormInputField, {
  FormInputFieldType
} from "../../common/components/FormInputField";
import CommonLoader, {
  LoaderStatus
} from "../../common/components/CommonLoader";

const AdminUserForm = ({
  title,
  loaderStatus,
  onDelete,
  allowDelete = false
}) => {
  const { t } = useTranslation("adminUsers");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onDeleteConfirmed = () => {
    setShowDeleteDialog(false);
    onDelete();
  };

  return (
    <>
      <Form autoComplete="off">
        <CardHeader>
          <CardTitle>
            <div className="d-flex">
              <h4 className="flex-grow-1">{t(title)}</h4>
            </div>
          </CardTitle>
        </CardHeader>
        <CommonLoader status={loaderStatus} defaultFailureMessage>
          <CardBody>
            <div className="form-horizontal">
              <FormInputField name="email" label={t("createForm.email")} />
              <FormInputField
                autoComplete="new-password"
                name="password"
                label={t("createForm.password")}
                type={FormInputFieldType.PASSWORD}
              />
              <FormInputField
                autoComplete="new-password"
                name="confirmPassword"
                label={t("createForm.confirmPassword")}
                type={FormInputFieldType.PASSWORD}
              />
            </div>
            <Row>
              <Col md="3" />
              <Col md="9" justify="start" className="text-right">
                <Button className="btn-round" color="info" type="submit">
                  {t("createForm.submitButton")}
                </Button>
                {allowDelete && (
                  <Button
                    className="btn-round"
                    color="danger"
                    type="button"
                    onClick={() => setShowDeleteDialog(true)}
                  >
                    {t("editForm.deleteButton")}
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
        </CommonLoader>
        {showDeleteDialog && (
          <ReactBSAlert
            danger
            showCancel
            cancelBtnText={t("common:cancel")}
            confirmBtnText={t("common:delete")}
            confirmBtnBsStyle="danger"
            title={t("editForm.deleteDialogTitle")}
            onConfirm={onDeleteConfirmed}
            onCancel={() => setShowDeleteDialog(false)}
          />
        )}
      </Form>
    </>
  );
};
AdminUserForm.defaultProps = {
  loaderStatus: LoaderStatus.NOT_PENDING
};

AdminUserForm.propTypes = {
  title: PropTypes.string.isRequired,
  loaderStatus: PropTypes.string
};

export default AdminUserForm;
