import { CLOSE_ALERT, SHOW_ALERT } from "./AlertReducer";
import AlertVariant from "./AlertVariant";

export const showAlert = (text, variant, duration = null) => dispatch => {
  dispatch(closeAlert());
  dispatch({
    type: SHOW_ALERT,
    payload: {
      text,
      variant,
      duration
    }
  });
  if (duration) {
    setTimeout(() => dispatch(closeAlert()), duration);
  }
};

export const closeAlert = () => dispatch => dispatch({ type: CLOSE_ALERT });

export const handleError = (err = "default") => dispatch => {
  let errorCode;
  if (err.errorCode) {
    errorCode = err.errorCode;
  } else {
    switch (err.status) {
      case 404:
        errorCode = "SERVER_NOT_RESPONDING";
        break;
      default:
        errorCode = "default";
    }
  }
  dispatch(showAlert(`error:${errorCode}`, AlertVariant.DANGER, 5000));
};

export const showCommonError = () => dispatch => {
  dispatch(showAlert("errors:default", AlertVariant.DANGER, 5000));
};
