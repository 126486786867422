import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";

const TablePagination = ({ onOffsetChange, offset, pageSize, total }) => {
  const prevEnabled = offset !== 0;
  const nextEnabled = offset + pageSize < total;

  const currentPage = Math.ceil(offset ? offset / pageSize : 0);
  const pages = _.range(total / pageSize);

  const closestPages =
    pages.size < 5
      ? pages
      : pages.filter(page => Math.abs(currentPage - page) <= 2);
  const hasPreviousPages = pages.some(page => page < _.min(closestPages));
  const hasNextPages = pages.some(page => page > _.max(closestPages));

  return (
    <>
      {total ? (
        <Pagination>
          <PaginationItem disabled={!prevEnabled}>
            <PaginationLink onClick={() => onOffsetChange(offset - pageSize)}>
              &laquo;
            </PaginationLink>
          </PaginationItem>
          {hasPreviousPages && <span>...</span>}
          {closestPages.map(page => (
            <PaginationItem key={page} active={page === currentPage}>
              <PaginationLink onClick={() => onOffsetChange(page * pageSize)}>
                {page + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
          {hasNextPages && <span>...</span>}
          <PaginationItem disabled={!nextEnabled}>
            <PaginationLink onClick={() => onOffsetChange(offset + pageSize)}>
              &raquo;
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      ) : null}
    </>
  );
};

TablePagination.defaultProps = {
  offset: 0,
  pageSize: 10,
  total: 0
};

TablePagination.propTypes = {
  onOffsetChange: PropTypes.func.isRequired,
  offset: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number
};

export default TablePagination;
