import React from "react";
import { Line } from "react-chartjs-2";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

const chartOptions = {
  legend: {
    display: false
  },

  tooltips: {
    enabled: true
  },

  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: false,
          maxTicksLimit: 10
        },
        gridLines: {
          drawBorder: true,
          zeroLineColor: "transparent",
          color: "rgba(255,255,255,0.05)"
        }
      }
    ],

    xAxes: [
      {
        gridLines: {
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f"
        }
      }
    ]
  }
};

const DashboardUsageChart = ({ labels, stats }) => {
  const { t } = useTranslation();

  const data = {
    labels,
    datasets: [
      {
        label: t("dashboard:usageChart.label"),
        borderColor: "#6bd098",
        pointRadius: 2,
        pointHoverRadius: 5,
        cubicInterpolationMode: "monotone",
        fill: true,
        borderWidth: 2.65,
        data: stats
      }
    ]
  };

  return (
    <Card>
      <CardBody>
        <CardHeader className="pb-3 text-center">
          {t("dashboard:usageChart.title")}
        </CardHeader>
        <Line data={data} options={chartOptions} />
      </CardBody>
    </Card>
  );
};

export default DashboardUsageChart;
