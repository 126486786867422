import React, { useEffect } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Field, getIn } from "formik";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const FormSelectFieldInner = props => {
  const {
    label,
    placeholder,
    form,
    field,
    options,
    onChangeHandler,
    onInputChange = () => {},
    disabled,
    defaultValue
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (!getIn(form.values, field.name) && defaultValue) {
      form.setFieldValue(field.name, defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const value = getIn(form.values, field.name);

  return (
    <Row>
      <Col>
        <Label>{label}</Label>
        <FormGroup>
          <Select
            classNamePrefix="form-select"
            options={options}
            placeholder={placeholder}
            value={value}
            isDisabled={form.isSubmitting || disabled}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onInputChange={onInputChange}
            onChange={option => {
              form.setFieldValue(field.name, option);
              onChangeHandler(option);
            }}
          />
          {(form.submitCount > 0 || touched) && error && error.value && (
            <label style={{ color: "red " }}>{t(error.value)}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormSelectField = props => {
  return <Field {...props} component={FormSelectFieldInner} />;
};

FormSelectField.defaultProps = {
  placeholder: "",
  options: [],
  onChangeHandler: () => {},
  disabled: false,
  defaultValue: {}
};

FormSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.object
};

export default FormSelectField;
