import { apiClient } from "../common/api/ApiClient";
import {
  handleError,
  showAlert
} from "../common/components/alert/AlertActions";
import AlertVariant from "../common/components/alert/AlertVariant";
import history from "../history";
import { PrepareUrl } from "../common/helpers/PrepareUrl";
import RouterPaths from "../common/constants/RouterPaths";

export const fetchLocations = (offset = 0, pageSize = 10) => (
  dispatch,
  getState
) => {
  const isAdmin = getState().auth.get("isAdmin");
  const url = isAdmin
    ? `/authorized/super_admin/locations/`
    : `/authorized/admin/locations/`;
  return apiClient
    .get(`${url}?pageSize=${pageSize}&offset=${offset}`)
    .catch(err => console.error(err));
};

export const fetchLocationUsers = (
  id,
  phone,
  username,
  offset = 0,
  pageSize = 10
) =>
  apiClient
    .get(
      `/authorized/admin/users/${id}?phone=${phone}&username=${username}&offset=${offset}&pageSize=${pageSize}`
    )
    .catch(err => console.error(err));

export const assignLocation = (id, userPayload) => {
  const { phone, username, expirationDate, daysPerYear } = userPayload;
  return apiClient.post("/authorized/admin/locations/assign", {
    locationId: id,
    userPhoneNumber: phone,
    userName: username,
    daysPerYear,
    expirationDate
  });
};

export const deleteAdminAssignment = (locationId, adminUserId) => dispatch =>
  apiClient
    .delete(
      `/authorized/super_admin/location/user?locationId=${locationId}&adminUserId=${adminUserId}`
    )
    .then(() => {
      dispatch(
        showAlert(
          "deleteAdminLocationAssignmentDialog:deleteSuccess",
          AlertVariant.SUCCESS,
          5000
        )
      );
      history.push(RouterPaths.ADMIN_EMPTY_PAGE);
      history.replace(
        PrepareUrl(RouterPaths.ADMIN_LOCATION_ADMINS, { id: locationId })
      );
    })
    .catch(err => dispatch(handleError(err)));

export const deleteUserLocation = (userLocationId, locationId) => dispatch =>
  apiClient
    .delete(`/authorized/admin/location/user/${userLocationId}`)
    .then(() => {
      history.push(PrepareUrl(RouterPaths.LOCATION_USERS, { id: locationId }));
      dispatch(
        showAlert(
          "locations:editForm.deleteDialog.deleteSuccess",
          AlertVariant.SUCCESS,
          5000
        )
      );
    })
    .catch(err => dispatch(handleError(err)));

export const fetchUserLocation = userLocationId => dispatch =>
  apiClient
    .get(`/authorized/admin/location/user/${userLocationId}`)
    .then(res => res.data)
    .catch(err => dispatch(handleError(err)));

export const updateUserLocation = (userLocationId, payload) => dispatch => {
  const { phone, username, expirationDate, daysPerYear } = payload;
  return apiClient
    .put(`/authorized/admin/location/user/${userLocationId}`, {
      userName: username,
      phoneNumber: phone,
      expirationDate,
      daysPerYear
    })
    .catch(err => {
      dispatch(handleError(err));
      throw err;
    });
};

export const fetchLocation = locationId => dispatch =>
  apiClient
    .get(`/authorized/location/${locationId}`)
    .then(res => res.data)
    .catch(err => {
      dispatch(handleError(err));
      throw err;
    });
