import React, { useState } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import dateFormat from "dateformat";
import { useTranslation } from "react-i18next";
import { has } from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { deleteAdminAssignment } from "../../locations/LocationsActions";
import CommonConfirmDialog from "../../common/components/CommonConfirmDialog";

const LocationAdminUsersTableRow = ({
  item,
  location,
  deleteAdminAssignment
}) => {
  const { t } = useTranslation();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <>
      <td>{item.email}</td>
      <td>{item.userType}</td>
      <td>{t(item.active ? "isActive" : "notActive")}</td>
      <td>{dateFormat(item.createdDateTime, "yyyy-mm-dd")}</td>
      <td className="text-right">
        {has(item, "adminId") && has(location, "locationId") && (
          <>
            <Button
              className="btn-icon"
              color="danger"
              id={`tooltip${item.id}`}
              size="sm"
              onClick={() => setShowDeleteDialog(true)}
            >
              <i className="fa fa-times" />
            </Button>
            <UncontrolledTooltip delay={0} target={`tooltip${item.id}`}>
              {t("deleteAccess")}
            </UncontrolledTooltip>
            <CommonConfirmDialog
              title="deleteAdminLocationAssignmentDialog:title"
              open={showDeleteDialog}
              content="deleteAdminLocationAssignmentDialog:content"
              positiveButtonText="delete"
              handleClose={() => setShowDeleteDialog(false)}
              onConfirmed={() => {
                setShowDeleteDialog(false);
                setTimeout(
                  () =>
                    deleteAdminAssignment(location.locationId, item.adminId),
                  500
                );
              }}
            />
          </>
        )}
      </td>
    </>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ deleteAdminAssignment }, dispatch);

export default connect(null, mapDispatchToProps)(LocationAdminUsersTableRow);
