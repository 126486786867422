export const ADMIN_PREFIX = "/admin";
export default {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  ADMIN_USERS: `${ADMIN_PREFIX}/users`,
  CREATE_ADMIN_USER: `${ADMIN_PREFIX}/user/create`,
  EDIT_ADMIN_USER: `${ADMIN_PREFIX}/user/edit/:id`,
  ADMIN_LOCATIONS: `${ADMIN_PREFIX}/locations`,
  ADMIN_LOCATION_ADMINS: `${ADMIN_PREFIX}/location/admins/:id`,
  ADMIN_LOCATION_USERS: `${ADMIN_PREFIX}/location/users/:id`,
  ADMIN_ASSIGN_LOCATION: `${ADMIN_PREFIX}/location/assign/:id`,
  LOCATIONS: "/locations",
  LOCATION_USERS: "/location/users/:id",
  ASSIGN_LOCATION: "/location/assign/:id",
  LOCATION_USER_EDIT: "/location/user/:id",
  ADMIN_EMPTY_PAGE: `${ADMIN_PREFIX}/empty_page`
};
