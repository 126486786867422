import * as Yup from "yup";

const maxDate = new Date("9999-12-31");
export default () => {
  return Yup.object().shape({
    phone: Yup.string()
      .length(9, "validators:phone")
      .test("digits test", "validators:phone", value =>
        Number.isInteger(Number(value))
      ),
    username: Yup.string().required("validators:required"),
    expirationDate: Yup.date()
      .max(maxDate, "validators:incorrectDate")
      .nullable()
  });
};
