import { apiClient } from "../../common/api/ApiClient";
import {
  handleError,
  showAlert
} from "../../common/components/alert/AlertActions";
import history from "../../history";
import RouterPaths from "../../common/constants/RouterPaths";
import AlertVariant from "../../common/components/alert/AlertVariant";
import { PrepareUrl } from "../../common/helpers/PrepareUrl";

export const fetchAdminUsers = (
  offset = 0,
  pageSize = 10,
  emailSearch = "",
  statusSearch = null
) => {
  let requestString = `/authorized/super_admin/users?pageSize=${pageSize}&offset=${offset}&email=${emailSearch}`;
  if (statusSearch) requestString += `&active=${statusSearch}`;
  return apiClient.get(requestString);
};

export const createAdminUser = ({
  email,
  password,
  confirmPassword
}) => dispatch => {
  return apiClient
    .post("/authorized/super_admin/user", {
      email,
      password,
      passwordConfirm: confirmPassword
    })
    .then(() => {
      history.push(RouterPaths.ADMIN_USERS);
      dispatch(
        showAlert("adminUsers:createForm.success", AlertVariant.SUCCESS, 5000)
      );
    })
    .catch(err => {
      dispatch(handleError(err));
      throw err;
    });
};

export const assignAdminLocation = (locationId, adminUserId) => dispatch =>
  apiClient
    .post("authorized/super_admin/locations/assign", {
      adminUserId,
      locationId
    })
    .then(() => {
      dispatch(
        showAlert("assignAdminLocation:success", AlertVariant.SUCCESS, 5000)
      );
      history.push(
        PrepareUrl(RouterPaths.ADMIN_LOCATION_ADMINS, { id: locationId })
      );
    })
    .catch(err => dispatch(handleError(err)));

export const fetchAdminUserById = adminId => dispatch => {
  return apiClient
    .get(`/authorized/super_admin/user/${adminId}`)
    .then(res => res.data)
    .catch(err => dispatch(handleError(err)));
};

export const updateAdminUser = (adminId, payload) => dispatch => {
  const { email, password, confirmPassword } = payload;
  return apiClient
    .put(`/authorized/super_admin/user/${adminId}`, {
      email,
      password: password || null,
      passwordConfirm: confirmPassword || null
    })
    .then(() => {
      dispatch(
        showAlert("adminUsers:editForm.success", AlertVariant.SUCCESS, 5000)
      );
      history.push(RouterPaths.ADMIN_USERS);
    })
    .catch(err => {
      dispatch(handleError(err));
      throw err;
    });
};

export const deleteAdminUser = adminId => dispatch => {
  return apiClient
    .delete(`/authorized/super_admin/user/${adminId}`)
    .then(() => {
      dispatch(
        showAlert(
          "adminUsers:editForm.deleteSuccess",
          AlertVariant.SUCCESS,
          5000
        )
      );
      history.push(RouterPaths.ADMIN_USERS);
    })
    .catch(err => dispatch(handleError(err)));
};
