const yup = require("yup");

const schema = yup.object().shape({
  email: yup
    .string()
    .email("validators:invalidEmail")
    .required("validators:required"),
  password: yup.string().required("validators:required")
});

export default schema;
