import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import { fill, get, has } from "lodash";
import { connect } from "react-redux";
import moment from "moment";
import DashboardStatsCard from "./DashboardStatsCard";
import { fetchDashboardStats, fetchUsageStats } from "./DashboardActions";
import CommonLoader, { LoaderStatus } from "../common/components/CommonLoader";
import DashboardUsageChart from "./DashboardUsageChart";

const labelDateFormat = "DD.MM";

const generateDateLabels = () => {
  const labels = [];
  for (let day = 0; day < 14; day++) {
    labels.push(
      moment()
        .subtract(day, "d")
        .format(labelDateFormat)
    );
  }
  return labels;
};

const labels = generateDateLabels().reverse();

const Dashboard = ({ fetchDashboardStats, fetchUsageStats }) => {
  const [stats, setStats] = useState();
  const [loaderStatus, setLoaderStatus] = useState(LoaderStatus.NOT_PENDING);
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    fetchUsageStats()
      .then(statsArray => {
        const mapped = fill(Array(labels.length), 0);
        statsArray.forEach(item => {
          if (has(item, "openDay") && has(item, "amountOfOpens")) {
            const key = moment(item.openDay).format(labelDateFormat);
            const index = labels.indexOf(key);
            if (index > -1) {
              mapped[index] = item.amountOfOpens;
            }
          }
        });
        return mapped;
      })
      .then(statsArray => setChartData(statsArray))
      .catch(() => {});
  }, [fetchUsageStats]);

  useEffect(() => {
    setLoaderStatus(LoaderStatus.IN_PROGRESS);
    fetchDashboardStats()
      .then(data => {
        setStats(data);
        setLoaderStatus(LoaderStatus.SUCCESS);
      })
      .catch(() => setLoaderStatus(LoaderStatus.FAILURE));
  }, [fetchDashboardStats]);

  return (
    <>
      <div className="content">
        <CommonLoader status={loaderStatus}>
          <Row className="justify-content-center">
            <Col xl="4" sm="6" xs="12">
              <DashboardStatsCard
                title="dashboard:locationCount"
                value={get(stats, "locationCount", "-")}
                iconClass="fa fa-map-marker-alt text-warning"
              />
            </Col>
            <Col xl="4" sm="6" xs="12">
              <DashboardStatsCard
                title="dashboard:userCount"
                value={get(stats, "userCount", "-")}
                iconClass="fa fa-users text-warning"
              />
            </Col>
            <Col xs="12" xl="8">
              <DashboardUsageChart stats={chartData} labels={labels} />
            </Col>
          </Row>
        </CommonLoader>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDashboardStats,
      fetchUsageStats
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Dashboard);
